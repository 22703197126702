import React from 'react'
import Body1 from '../../tags/body1'
import BoldBase from '../../tags/boldBase'
import H1 from '../../tags/h1'
import HistoryList from '../../../data/history.json';
import { useTranslation } from 'react-i18next';
import {Korean} from '../../../../i18n'

const HistoryWidth = (HistoryList.length + 1) * 300;
const AboutHistory = () => {
  
  const {t} = useTranslation();
  return (
    <article className="relative w-full lg:max-w-7xl mx-auto  py-[124px] overflow-x-auto">
      <HistoryTopArea text={t}/>
      <AreaBorder />
    </article>
  )
}

const AreaBorder = () => (
  <div className={`absolute h-[396px] top-0 ml-[104px] border-main border-2 border-r-0 rounded-tl-3xl rounded-bl-3xl`} style={{width : `${HistoryWidth}px`}}/>
)
const HistoryTopArea = ({text}) => (
  <section className={`mt-[24px] ml-[160px]`} style={{width : `${HistoryWidth}px`}}>
    <div className="flex items-start space-x-[88px]">
      <H1 className="font-metropolis">History</H1>
      <Body1 className="whitespace-pre-line">{text("introduce.historyDesc")}</Body1>
    </div>
    <Annals />
  </section>
)

const Annals = () => {
  
  const sortedHistory = HistoryList.sort((a,b) => a.year < b.year ? 1 : -1)
  return (
    <div className="mt-[113px] flex flex-row space-x-5">
      {sortedHistory.map(({year, period}, idx) => 
        <Period key={idx} year={year} list={period} />
      )}
    </div>
  )
}
const Period = ({year, list}) => (
  <div className="flex flex-col">
    <PeriodYear>{year ?? 2021}</PeriodYear>
    <Dot />
    <section className="mt-[30px] space-y-4">
      {
        list.map(({date, content}, idx) => 
          <HistoryItem key={idx} date={date} content={content} />
        )
      }
    </section>
  </div>
)
const PeriodYear = ({children}) => <span className="text-h1 leading-h1 text-main font-bold font-metropolis">{children}</span>
const Dot = () => (
  <div className="mt-9 w-[10px] h-[10px] bg-main rounded-full"></div>
)

const HistoryItem = ({date, content}) => {
  const {i18n} = useTranslation();
  const isKorean = i18n.language === Korean;
  return (
    <div className="flex flex-row space-x-5">
      <BoldBase className="font-metropolis">{date}</BoldBase>
      <div className="flex flex-col space-y-4">
        {
          content[ isKorean ? "kr" : "en"].map(
            (text, idx) => <p key={idx} className="w-[240px] text-base text-gray-080 font-light">{text}</p>)
        }
      </div>
    </div>
  )
}
export default AboutHistory;