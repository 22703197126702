import React from 'react'

const Customized = () => (
  <svg width="79" height="79" viewBox="0 0 79 79" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M0 0H32V11.101C33.2705 9.80447 35.0413 9 37 9C40.866 9 44 12.134 44 16C44 19.866 40.866 23 37 23C35.0413 23 33.2705 22.1955 32 20.899V32H20.899C22.1955 30.7295 23 28.9587 23 27C23 23.134 19.866 20 16 20C12.134 20 9 23.134 9 27C9 28.9587 9.80447 30.7295 11.101 32H0V0Z" fill="#4E79E7"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M15 79L15 47L26.101 47C24.8045 45.7295 24 43.9587 24 42C24 38.134 27.134 35 31 35C34.866 35 38 38.134 38 42C38 43.9587 37.1955 45.7295 35.899 47L47 47L47 58.101C45.7295 56.8045 43.9587 56 42 56C38.134 56 35 59.134 35 63C35 66.866 38.134 70 42 70C43.9587 70 45.7295 69.1955 47 67.899L47 79L15 79Z" fill="#B9D2FC"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M79 79L47 79L47 67.899C45.7295 69.1955 43.9587 70 42 70C38.134 70 35 66.866 35 63C35 59.134 38.134 56 42 56C43.9587 56 45.7295 56.8045 47 58.101L47 47L58.101 47C56.8045 48.2705 56 50.0413 56 52C56 55.866 59.134 59 63 59C66.866 59 70 55.866 70 52C70 50.0413 69.1955 48.2705 67.899 47L79 47L79 79Z" fill="#4E79E7"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M79 15L79 47L67.899 47C69.1955 48.2705 70 50.0413 70 52C70 55.866 66.866 59 63 59C59.134 59 56 55.866 56 52C56 50.0413 56.8045 48.2705 58.101 47L47 47L47 35.899C48.2705 37.1955 50.0413 38 52 38C55.866 38 59 34.866 59 31C59 27.134 55.866 24 52 24C50.0413 24 48.2705 24.8045 47 26.101L47 15L79 15Z" fill="#B9D2FC"/>
  </svg>

)
export default Customized;