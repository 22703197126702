import React from 'react'
import SafeCross from './safeCross';
import SafeHand from './safeHand';

const Safe = () => (
  <i className="relative w-20 h-20 flex flex-col justify-end">
    <i className="absolute left-[22px] top-0"><SafeCross /></i>
    <i className="items-end"><SafeHand /></i>
  </i>
)
export default Safe;