import { StaticImage } from 'gatsby-plugin-image';
import React from 'react'
import { useTranslation } from 'react-i18next';
import Body1 from '../../tags/body1';
import BoldBase from '../../tags/boldBase';
import H1 from '../../tags/h1';

const AboutCompany = () => {
  
  const {t} = useTranslation();
  return (
    <article className="w-full lg:max-w-7xl mx-auto flex flex-col lg:flex-row lg:justify-between py-36">
      <TextContent text={t} />
      <CompanyImage text={t} />
    </article>
  )
}

const TextContent = ({text}) => (
  <section className="flex flex-col pl-4 md:pl-page-x-sm lg:pl-page-x-s">
    <H1 className="text-[44px] leading-[44px] font-bold text-gray-080 font-metropolis">About<br />Our Company</H1>
    <Body1 className="mt-8 max-w-[380px]">{text("introduce.about")}</Body1>
    <CompanyInfomation text={text} />
  </section>
)
const CompanyInfomation = ({text}) => (
  <div className="mt-7 space-y-[20px]">
    <InfoContent title="Corporate name" content={text("introduce.company")}/>
    <InfoContent title="Date" content="2018.11"/>
    <InfoContent title="CEO" content={text("introduce.ceo")}/>
    <InfoContent title="Telephone" content="+82 2-6085-1105"/>
    <InfoContent title="Main Office" content={text("introduce.mainOffice")}/>
    <InfoContent title="R&D Office" content={text("introduce.rndOffice")}/>
  </div>
)

const InfoContent = ({title, content}) => (
  <div className="flex flex-row space-x-5">
    <BoldBase className="min-w-[143px] font-metropolis">{title}</BoldBase>
    <Body1 className="max-w-[307px]">{content}</Body1>
  </div>
)
const CompanyImage = () => (
  <div className="mt-14 lg:mt-0 flex justify-end">
    <StaticImage
      src="../../../images/about/about-company.png"
      alt="Breathings Company"
      className="rounded-tl-[36px] rounded-bl-[36px] border-0"
      width={548}
      height={472}
    />
  </div>
)
export default AboutCompany;