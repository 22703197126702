import React from 'react'

const SafeHand = () => (
<svg width="78" height="58" viewBox="0 0 78 58" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_57_2772)">
    <path d="M77.94 6.15002V31.2501L69.74 47.66V57.4301H41.02V40.54L41.62 39.9401L59.22 22.34C59.5227 22.0458 59.8583 21.7875 60.22 21.5701C61.0186 21.0537 61.923 20.7236 62.8664 20.6041C63.8098 20.4847 64.768 20.579 65.67 20.8801V6.15002C65.7341 4.56054 66.4106 3.05752 67.5579 1.95557C68.7051 0.85361 70.2342 0.238159 71.825 0.238159C73.4158 0.238159 74.9449 0.85361 76.0922 1.95557C77.2394 3.05752 77.9159 4.56054 77.98 6.15002H77.94Z" fill="#B9D2FC"/>
    <path d="M36.92 40.54V57.47H8.20001V47.7L0 31.29V6.19006C0 4.55898 0.647982 2.99466 1.80133 1.84131C2.95468 0.68796 4.51894 0.0400391 6.15002 0.0400391C7.78111 0.0400391 9.34537 0.68796 10.4987 1.84131C11.6521 2.99466 12.3 4.55898 12.3 6.19006V20.84C13.2037 20.5388 14.1635 20.4445 15.1085 20.5639C16.0536 20.6833 16.9597 21.0135 17.76 21.53C18.1218 21.7475 18.4573 22.0059 18.76 22.3L36.92 40.54Z" fill="#B9D2FC"/>
  </g>
  <defs>
    <clipPath id="clip0_57_2772">
      <rect width="77.94" height="57.43" fill="white"/>
    </clipPath>
  </defs>
</svg>
)
export default SafeHand