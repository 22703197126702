import React from 'react'
import { useTranslation } from 'react-i18next'
import DownloadIcon from '../icons/download'
import CompanyIntroduce from '../../catalog/Breathings.pdf';

export default function CompanyIntroduceDownload({className}) {
  const {t} = useTranslation();
  return (
    <a href={CompanyIntroduce} download className={`flex flex-row px-8 py-3 items-center rounded bg-main text-white font-bold ${className}`}>
      <span className="text-base">{t("companyIntroDown")}</span>
      <DownloadIcon className="ml-5"/>
    </a>
  )
}