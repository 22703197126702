import React from 'react'
import { useTranslation } from 'react-i18next';

const MainArticle = ({children=<></>}) => {
  
  const {t} = useTranslation();
  return (
    <article className="relative w-full h-article flex flex-col items-center text-[44px] font-thin text-white bg-center bg-cover bg-no-repeat bg-[url('../images/main-background.png')]">
      <MainTitle title={t("main.title")}/>
      <MainSubTitle />
      {children}
      <WhiteShadow />
    </article>
  )
}
export default MainArticle;

const MainTitle = ({title}) => (
  <section className="mt-60 flex flex-col items-center text-[44px] font-bold leading-[62px] whitespace-pre-line text-center">
    {title}
  </section>
)
const MainSubTitle = () => (
  <p className="text-2xl leading-6 text-white mt-10 mb-14 text-center font-normal">Let’s keep our lungs healthy on our own.</p>
)
const WhiteShadow = () => (
  <div className="absolute bottom-0 left-0 w-full h-[340px] block bg-gradient-to-b from-transparent to-white " />
)
const LeftWhiteLayer = () => (
  <div className="absolute left-0 top-0 h-full w-full lg:w-2/12 bg-gradient-to-r from-white to-transparent">
  </div>
)
const RightWhiteLayer = () => (
  <div className="absolute right-0 top-0 h-full w-full lg:w-2/12 bg-gradient-to-l from-white to-transparent">
  </div>
)