import React from 'react'
import CompanyIntroduceDownload from '../components/button/componyIntroduce';
import MainArticle from '../components/MainArticle';
import AboutCompany from '../components/pages/about/aboutCompany';
import CorporateValues from '../components/pages/about/corporateValues';
import AboutHistory from '../components/pages/about/history';
import Main from '../layout/main'

const AboutPage = (props) => (
  <Main title="브레싱스 회사소개" {...props}>
    <MainArticle>
      <CompanyIntroduceDownload className="z-10"/>
    </MainArticle>
    <AboutCompany />
    <AboutHistory />
    <CorporateValues />
  </Main>
)

export default AboutPage;