import React from 'react'
import { useTranslation } from 'react-i18next'
import Customized from '../../icons/customized'
import Safe from '../../icons/safe'
import Technology from '../../icons/technology'
import Body1 from '../../tags/body1'
import H1 from '../../tags/h1'

const CorporateValues = () => {
  
  const {t} = useTranslation();
  return (
    <article className="w-full  lg:max-w-7xl mx-auto flex flex-col py-[124px] px-4 sm:px-page-x-sm lg:px-page-x-s  bg-subBgc2">
      <CorporateTitle />
      <CorporateDesc text={t}/>
      <CorporateDetail text={t}/>
    </article>
)}
const CorporateTitle = () => (
  <H1 className="max-w-[281px] font-metropolis">Corporate Values</H1>
)
const CorporateDesc = ({text}) => (
  <Body1 className="mt-8 text-left lg:text-center">
    {text("introduce.corporateDesc")}
  </Body1>
)
const CorporateDetail = ({text}) => (
  <section className="w-full mt-11 flex flex-col items-center lg:items-start lg:flex-row lg:justify-center space-y-11 lg:space-y-0 lg:space-x-5">
    <CorporateValue 
      Icon={<Customized />} 
      title="Customized" 
      desc={text("introduce.customized")}
    />
    <CorporateValue 
      Icon={<Technology />} 
      title="Technology" 
      desc={text("introduce.technology")}
    />
    <CorporateValue
      Icon={<Safe />}
      title="Safe"
      desc={text("introduce.safe")}
    />
  </section>
)

const CorporateValue = ({Icon, title, desc}) => (
  <div className="w-[308px] flex flex-col items-center">
    <div className="w-[200px] h-[200px] rounded-full bg-white flex justify-center items-center">
      {Icon}
    </div>
    <div className="mt-3 text-main text-2xl leading-9 font-bold">{title}</div>
    <Body1 className="mt-3 text-center whitespace-pre-line">{desc}</Body1>
  </div>
)
export default CorporateValues;